/* MainLayout.css */

/* Main layout container styling */
.responsive-container {
  width: 100%;
  overflow: auto;
}

/* Styling for the content wrapper */
.responsive-content-wrapper {
  width: 100%;
  min-width: 1225px;
  max-width: 1864px;
}

/* Media query for larger screens */
@media (min-width: 1864px) {
  .responsive-container {
    display: flex;
    justify-content: center;
  }
}

/* Main section styles */
.main-layout {
  background-color: #F8F8FB;
  overflow: hidden;
}
